<template>
    <div class="bgc-white p-20 bd" id="mainContent">
      <h4 class="c-grey-900">إضافة فديو</h4>
      <div class="mT-30">
        <form @submit="checkForm">
          <div class="form-row mT-20">
            <div class="form-group col-md-12">
              <label for="inputEmail4">إسم الفديو</label
              ><input type="name" class="form-control" id="inputEmail4" v-model="videoName" required  style="padding:17px 0"/>
            </div>
          </div>
  
          <div class="form-row mT-20">
            <input id="fileUpload" type="file" hidden accept=".mp4" @change="onFileSelected"/>
            <sui-button
            type="button"
            class="btn btn-primary mL-10"
            :content="video == null ? 'إختر فديو' : video.name"
            icon="film"
            @click="chooseFiles"
            label-position="left"
            :disabled="loading"
          />
          </div>
  
          <div class="form-row mT-20">
            <loading v-show="loading" />
          <sui-button
            type="submit"
            positive
            class="btn btn-primary mL-10"
            :content="$t('add')"
            icon="check"
            label-position="left"
            :disabled="loading"
            v-show="!loading"
          />
          <sui-button
            type="button"
            :content="$t('cancel')"
            :disabled="loading"
            v-show="!loading"
            @click="$emit('closePanel', {})"
          />
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
import { duration } from 'moment';

  export default {
    props: {
      videoID: {type: String},
      reload: { type: Function },
    },
    data() {
      return {
        videoName: "",
        video: null,
        loading: false,
      }
    },
    methods: {
      onFileSelected: function(event) {
        this.video = event.target.files[0];

        let videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(this.video);
      videoElement.preload = 'metadata';
      
      // Event listener for when metadata is loaded (including duration)
      videoElement.addEventListener('loadedmetadata', () => {
        URL.revokeObjectURL(videoElement.src); // Free up resources
        this.duration = this.formatDuration(videoElement.duration); // Duration in seconds
        
        // Optionally, format duration if needed (HH:mm:ss)
        // this.formattedDuration = this.formatDuration(this.duration);

        console.log('Duration:', this.duration);
        console.log('id:', this.videoID); // Example log, replace with actual usage

        // Now you can proceed with uploading the video or further handling
      });
            },
        chooseFiles: function() {
          document.getElementById("fileUpload").click()
      },
       formatDuration(seconds) {
  let date = new Date(null);
  date.setSeconds(seconds);
  
  // Extract minutes and seconds
  let minutes = date.getUTCMinutes();
  let remainingSeconds = date.getSeconds(); // Rename the variable to avoid re-declaration
  
  // Format as mm:ss
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
},
      checkForm: function (e) {
        e.preventDefault();
        this.loading = true;
        this.addVideo();
      },
      addVideo: function () {
        
        let data = new FormData();
  
        data.append('video', this.video);
        data.append('name',this.videoName);
        data.append('duration', this.duration);//put duration here
        console.log('id : '+this.videoID)
        data.append('subject_id',this.videoID);
  
  
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http
          .post(
            "https://yalla-emtihan.com/backend/videos/uploadVideo",
            data,
            { headers }
          )
  
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 201:
                  this.$emit("closePanel", {});
                  this.success(this.$t("success"));
                  this.reload();
                  break;
              } 
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
              }
            }
          );
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function () {
        this.$toast.success(this.$t("success"), {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  #mainContent {
    text-align: right;
    direction: rtl;
  }
  .ui {
      text-align: right !important;
  }
  
  </style>